import { TManifestFilters } from 'src/reducers/manifest';

export const SET_MANIFEST_SERVICE_APPLETS = 'manifest/SET_MANIFEST_SERVICE_APPLETS';
export const SET_MANIFEST_FILTERS = 'manifest/SET_MANIFEST_FILTERS';
export const CLEAR_MANIFEST_SERVICE_APPLETS = 'manifest/CLEAR_MANIFEST_SERVICE_APPLETS';
export const CLEAR_MANIFEST = 'manifest/CLEAR_MANIFEST';

export const setManifestServiceApplets = (value) => {
  return (dispatch) => {
    dispatch({ type: SET_MANIFEST_SERVICE_APPLETS, data: value });
  };
};

export const setManifestFilters = (value: Partial<TManifestFilters>) => {
  return (dispatch) => {
    dispatch({ type: SET_MANIFEST_FILTERS, data: value });
  };
};

export const clearManifestServiceApplets = () => {
  return (dispatch) => {
    dispatch({ type: CLEAR_MANIFEST_SERVICE_APPLETS });
  };
};

export const clearManifest = () => {
  return (dispatch) => {
    dispatch({ type: CLEAR_MANIFEST });
  };
};
