import { Nullable } from 'src/types/@custom_types/helpers.t';
import {
  CLEAR_MANIFEST,
  CLEAR_MANIFEST_SERVICE_APPLETS,
  SET_MANIFEST_FILTERS,
  SET_MANIFEST_SERVICE_APPLETS,
} from '../actions/manifest';
import moment, { Moment } from 'moment';

export type TManifestServiceApplets = {
  data: any[];
  isLoading: boolean;
  error: Nullable<string>;
};

export type TManifestFilters = {
  results: number;
  dateFilter: boolean;
  startTime: Moment;
  endTime: Moment;
  pageCount: number;
  eventsPerPage: number;
  offset: number;
  currentPage: number;
  sortValue: string;
  search: string;
  sort_by: string;
  apps: string[];
  sessionTableFilters: {
    session_ids: string[];
    vehicle_ids: string[];
    order_numbers: string[];
    upcs: string[];
    product_numbers: string[];
    classifications: string[];
    status: string[];
    critical_events: string[];
    sites: string[];
    sort: 'DESC' | 'ASC';
    tags: string[];
  };
  eventTableFilters: {
    sites: (string | number)[];
    feeders: (string | number)[];
    locations: (string | number)[];
    locations_sites: string[];
    locations_floors: string[];
    status: (string | number)[];
    apps: (string | number)[];
    event_types: (string | number)[];
    events: (string | number)[];
    tags: (string | number)[];
  };
};

export type TManifest = {
  serviceApplets: TManifestServiceApplets;
  filters: TManifestFilters;
};

export const initialState: TManifest = {
  serviceApplets: {
    data: [],
    isLoading: false,
    error: null,
  },
  filters: {
    results: 0,
    dateFilter: false,
    startTime: moment().startOf('day'),
    endTime: moment(),
    pageCount: 1, // default to zero
    eventsPerPage: 100,
    offset: 0,
    currentPage: 0,
    sortValue: 'Most Recent',
    search: '',
    sort_by: 'site_timestamp_utc',
    apps: [],
    sessionTableFilters: {
      session_ids: [],
      vehicle_ids: [],
      order_numbers: [],
      upcs: [],
      product_numbers: [],
      classifications: [],
      status: [],
      critical_events: [],
      sites: [],
      sort: 'DESC',
      tags: [],
    },
    eventTableFilters: {
      sites: [],
      locations: [],
      locations_sites: [],
      locations_floors: [],
      feeders: [],
      status: [],
      apps: [],
      event_types: [],
      events: [],
      tags: [],
    },
  },
};

export default (state = initialState, action) => {
  switch (action.type) {
    case SET_MANIFEST_SERVICE_APPLETS: {
      return { ...state, serviceApplets: { ...state.serviceApplets, ...action.data } as TManifestServiceApplets };
    }
    case SET_MANIFEST_FILTERS: {
      return { ...state, filters: { ...state.filters, ...action.data } as TManifestFilters };
    }
    case CLEAR_MANIFEST_SERVICE_APPLETS: {
      return {
        ...state,
        serviceApplets: {
          data: [],
          isLoading: false,
          error: null,
        },
      };
    }
    case CLEAR_MANIFEST: {
      return initialState;
    }
    case CLEAR_MANIFEST_SERVICE_APPLETS: {
      return { ...state, serviceApplets: initialState.serviceApplets };
    }
    default:
      return state;
  }
};
